import styles from "./Layout.module.css";

import ErrorBoundary from "./ErrorBoundary";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { CAPTURE_URL } from "../../Constants/Links";

import { Avatar, Box, ClickAwayListener, IconButton, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ExitFullScreenIcon, FeedbackIcon, FullScreenIcon, HelpIcon, InfoIcon, LogoutIcon, MenuIcon, PreferencesIcon, SkinAidActiveIconNew, SkinAidIconNew, SkinScannerActiveIconNew, SkinScannerIconNew } from "../Icons/Icons";
import { neutralblack, neutral80, neutral70, tertiary95, neutral90, secondary90, neutral50 } from "../../../Themes/dermusTheme";

import * as links from "../../Constants/Links";

import { checkUserValidity, logout } from "../../API/authenticationAPI";


import { useHistory } from "react-router-dom";
import { DERMUS_WIKI_LINK, DRAWER_SIZE, EXTENDED_DRAWER_SIZE, MOBILE_MODE, PREFERENCES, SHOW_TO_CAPTURE_TIP_DEFAULT_VALUE, START_FROM_CURRENT_LEVEL_DEFAULT_VALUE, USER_DATA_SIGNED_IN_LOCAL_STORAGE, ZINDEX_DRAWER } from "../../Constants/Constants";
import { buildSearchQuery, generateToken, getLesionId, getPatientId, getScreenShot } from "../../utility/utility";
import { isDemo, isSkinAid } from "../../utility/functions";

import { restoreItem, storeItem } from "../../API/localStorageAPI";
import { USER_EP } from "../../API/endPoints";
import useHttp, { GET } from "../../API/useHttp";
import PrimarySpinner from "../Spinner/PrimarySpinner";
import useWindowSize from "../../hooks/useWindowSize";
import { useCallback } from "react";
import { addUserInfoActionCreator } from "../../store/actionCreators/authenticationActionCreator";
import { processUser } from "../../API/dataRestructure";
import MoveToCaptureDialog from "./MoveToCaptureDialog";

import { ABOUT_LOWERCASE, FEEDBACK, FULLSCREEN_LOWERCASE, LOGOUT_LOWERCASE, PREFERENCES_LOWERCASE, PROFILE_LOWERCASE, SUPPORT_LOWERCASE } from "../../Constants/Message";
import useElementSize from "../../hooks/useElementSize";

const FULLSCREEN_STRING = "fullscreen"
const Layout = (props) => {
  //Dispatch
  const dispatch = useDispatch();
  //Cognito session
  const session = useSelector((state) => state.auth.session);
  const userInfo = useSelector((state) => state.auth.userInfo);
  //History
  const history = useHistory();
  //Current user monogram
  const [monogram, setMonogram] = useState("");
  //Window size
  const windowSize = useWindowSize()
  // location gives full url path
  const location = useLocation();
  //Patient id
  const [patientId, setPatientId] = useState(parseInt(getPatientId(location)))
  //Lesion Global ID
  //const [lesionGlobalId, setLesionGlobalId] = useState(null)
  //Lesion local id
  const [lesionLocalId, setLesionLocalId] = useState(parseInt(getLesionId(location)))
  //User preferences data
  const userPreferences = useSelector((state) => state.auth.userPreferences);
  //Show to capture tip
  const [showToCaptureTip, setShowToCaptureTip] = useState(!SHOW_TO_CAPTURE_TIP_DEFAULT_VALUE)
  //Set show select capture dialog
  const [showSelectCaptureDialog, setShowSelectCaptureDialog] = useState(false)
  //Start capture from current level
  const [startCaptureFromCurrentLevel, setStartCaptureFromCurrentLevel] = useState(START_FROM_CURRENT_LEVEL_DEFAULT_VALUE)
  //Open menu 
  const [openMenu, setOpenMenu] = useState(false);
  //Open user
  const [openUser, setOpenUser] = useState(false);
  // Get user info
  const [, sendRequestUser, , userPending] = useHttp();
  //Is fullscreen
  const [isFullscreen, setIsFullScreen] = useState(!!document.fullscreenElement)
  //Top menu size
  const [topMenuRef, topMenuSize] = useElementSize()

  //Display mode
  const [displayMode, setDisplayMode] = useState()


  const onFeedBack = async () => {
    setOpenMenu(false);
    history.push(links.FEEDBACK_URL, { screenshot: await getScreenShot(), url: window.location.href })
  }

  useEffect(() => {
    window.addEventListener('DOMContentLoaded', () => {
      const standaloneQuery = window.matchMedia('(display-mode: standalone)')
      const fullscreenQuery = window.matchMedia(`(display-mode: ${FULLSCREEN_STRING})`)
      let displayModeLocal = 'browser tab';
      if (standaloneQuery.matches) {
        displayModeLocal = 'standalone';
      }
      if (fullscreenQuery.matches) {
        displayModeLocal = FULLSCREEN_STRING;
      }
      // Log launch display mode to analytics
      setDisplayMode(displayModeLocal)

    });
  }, [])
  //User preferences
  useEffect(() => {
    if (userPreferences) {
      if (userPreferences[PREFERENCES.SHOW_TO_CAPTURE_TIP] !== null && userPreferences[PREFERENCES.SHOW_TO_CAPTURE_TIP] !== undefined) {
        setShowToCaptureTip(!userPreferences[PREFERENCES.SHOW_TO_CAPTURE_TIP])
      }
      if (userPreferences[PREFERENCES.START_FROM_CURRENT_LEVEL] !== null && userPreferences[PREFERENCES.START_FROM_CURRENT_LEVEL] !== undefined) {
        setStartCaptureFromCurrentLevel(userPreferences[PREFERENCES.START_FROM_CURRENT_LEVEL])
      }
    }
    //eslint-disable-next-line
  }, [userPreferences]);

  //Set Ids
  useEffect(() => {
    if (location) {
      setPatientId(parseInt(getPatientId(location)))
      setLesionLocalId(parseInt(getLesionId(location)))
    }// eslint-disable-next-line
  }, [location]);
  //Get user info on start
  useEffect(() => {
    const userData = JSON.parse(restoreItem(USER_DATA_SIGNED_IN_LOCAL_STORAGE))
    if (userData) {
      setMonogram(isDemo() ? "D" : ((userData?.name ? userData?.name[0] : "") + (userData?.surname ? userData?.surname[0] : "")))
      dispatch(addUserInfoActionCreator(userData))
    } else {
      if (session && !userPending) {
        sendRequestUser(USER_EP, GET, true, (data) => {
          data = processUser(data, session)
          const userData = {
            city: data.city, company: data.company, country: data.country, id: data.id,
            name: data.name,
            prefix: data.prefix,
            surname: data.surname,
            email: data.email,
            specialization: data.specialization
          }
          storeItem(USER_DATA_SIGNED_IN_LOCAL_STORAGE, JSON.stringify(userData));
          dispatch(addUserInfoActionCreator(userData))
          setMonogram(isDemo() ? "D" : ((userData?.name ? userData?.name[0] : "") + (userData?.surname ? userData?.surname[0] : "")))
        })
      }
    }// eslint-disable-next-line
  }, [session]);

  const { pathname } = useLocation();
  const view = useSelector((state) => state.patMan.view);
  const showToolbar = !(view?.isMobileOrTablet && (pathname.endsWith(CAPTURE_URL) && isDemo())) && session;

  //Chcek session is saved or not
  useEffect(() => {
    checkUserValidity(dispatch);
  }, [dispatch]);

  //Handle icon clicks
  const handleIconClick = (linkToRoute, search, diffOrigin = false) => {
    // route
    if (diffOrigin) {
      window.location.href = linkToRoute + search;
    } else {
      history.push({ pathname: linkToRoute, search: search });
    }
    //dispatch(setCaptureActionCreator(null))
  };

  //Handle full screen
  const handleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      if (document.body.requestFullscreen) {
        document.body.requestFullscreen();
      } else if (document.body.webkitRequestFullscreen) {
        /* Safari */
        document.body.webkitRequestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
  }, [])

  //handle exit fullscreen other method then fullscreen button
  useEffect(() => {
    const handleFullScreenChange = (e) => { setIsFullScreen(s => !s); }
    window.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => window.removeEventListener("fullscreenchange", handleFullScreenChange);
    //eslint-disable-next-line
  }, []);

  /* DISPLAY */
  return (
    <ErrorBoundary session={session} userInfo={userInfo}>
      {/* ORIGINAL LAYOUT FOR LOGIN, SIGNUP AND RESETPASSWORD SCREENS*/}
      <main className={showToolbar ? styles.Content : ""} >
        {session === undefined && !pathname.endsWith(links.VERIFY_EMAIL_URL) ? <Box sx={{ display: "flex", justifyContent: "center" }}>
          <PrimarySpinner />
        </Box> :
          (!session || pathname.endsWith(links.VERIFY_EMAIL_URL)) && !isDemo() ?
            <Box>
              {props.children}
            </Box>
            :
            <Box>
              {windowSize.width >= MOBILE_MODE ?
                <ClickAwayListener onClickAway={() => { setOpenMenu(false); setOpenUser(false) }}>
                  {/* NEW LAYOUT FOR LANDSCAPE MODE*/}
                  <Box
                    sx={{
                      width: EXTENDED_DRAWER_SIZE, flexShrink: 0,
                      position: "fixed",
                      top: "0px",
                      left: "0px",
                      height: `${windowSize.height}px`,
                      borderRadius: "0px 10px 10px 0px",
                      zIndex: ZINDEX_DRAWER
                    }}
                  >
                    {/* DRAWER BACKGROUND */}
                    <Box
                      sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: neutral80, borderRadius: "0px 10px 10px 0px" }}>

                      {/* ACCOUNT MENU */}
                      <Box sx={{ width: "100%", height: `${DRAWER_SIZE}px`, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "2px" }}>
                        <IconButton
                          style={{ color: neutralblack, borderRadius: 50 }}
                          sx={{ width: "48px", height: "48px" }}
                          onClick={() => { setOpenUser(s => !s); setOpenMenu(false); }}>
                          <Avatar sx={{ bgcolor: tertiary95, color: neutralblack, width: 42, height: 42, fontSize: "16px" }}>{monogram}</Avatar>
                        </IconButton>
                      </Box>

                      {/* CATALOG & CAPTURE */}
                      <Box sx={{
                        width: "100%",
                        height: `${windowSize.height - (2 * DRAWER_SIZE) - topMenuSize.height}px`,
                        backgroundColor: neutral90, borderRadius: "0px 10px 10px 0px",
                        marginTop: `${openUser ? ((windowSize.height - topMenuSize.height - 2 * DRAWER_SIZE) < 2 * DRAWER_SIZE ? 0 : topMenuSize.height) : 0}px`
                      }}>
                        {/* CATALOG - PATIENTS */}
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "10px" }}>
                          <IconButton
                            style={{
                              backgroundColor: isSkinAid() && (location?.pathname === "/" || location.pathname.includes("addpatient") || location.pathname.includes("editpatient") || patientId) ? secondary90 : null,
                              color: neutralblack, borderRadius: 10
                            }}

                            sx={{ width: "48px", height: "48px" }}
                            onClick={() => {
                              setOpenMenu(false); setOpenUser(false);
                              handleIconClick(isSkinAid() ?
                                "/" : links.SKINAID_FULL_URL,
                                isSkinAid() ? "" : `?${buildSearchQuery({ userToken: generateToken({ user: session.getIdToken().payload.sub }) })}`,
                                !isSkinAid())
                            }}>
                            {(isSkinAid() && (location?.pathname === "/" || location.pathname.includes("addpatient") || location.pathname.includes("editpatient") || patientId))
                              ? <SkinAidActiveIconNew /> : < SkinAidIconNew />}
                          </IconButton>
                        </Box>
                        {/* CAPTURE */}
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "2px" }}>
                          <IconButton style={{
                            backgroundColor: location.pathname.includes("capture") ? secondary90 : null,
                            color: neutralblack, borderRadius: 10
                          }} sx={{ width: "48px", height: "48px" }}
                            onClick={() => {
                              setOpenMenu(false); setOpenUser(false)
                              if (isSkinAid() && !showToCaptureTip &&
                                (((patientId && !location.pathname.includes("addpatient"))) ||      // patient level
                                  (lesionLocalId && !location.pathname.includes("addlesion")))      // lesion level
                              ) {
                                //Showing dialog to user to select
                                setShowSelectCaptureDialog(true)
                              } else {
                                let search = null
                                if (patientId && lesionLocalId && !location.pathname.includes("addlesion") && startCaptureFromCurrentLevel) {
                                  if (isSkinAid()) {
                                    search = `?${buildSearchQuery({ patient: patientId, lesion: lesionLocalId, userToken: generateToken({ user: session.getIdToken().payload.sub }) })}`
                                  } else {
                                    search = `?${buildSearchQuery({ patient: patientId, lesion: lesionLocalId })}`
                                  }
                                } else if (patientId && !location.pathname.includes("addpatient") && startCaptureFromCurrentLevel) {
                                  if (isSkinAid()) {
                                    search = `?${buildSearchQuery({ patient: patientId, userToken: generateToken({ user: session.getIdToken().payload.sub }) })}`
                                  } else {
                                    search = `?${buildSearchQuery({ patient: patientId, })}`
                                  }
                                } else {
                                  if (isSkinAid()) {
                                    search = `?${buildSearchQuery({ userToken: generateToken({ user: session.getIdToken().payload.sub }) })}`
                                  }
                                }
                                //Not showing the dialog
                                handleIconClick((isSkinAid() ? (links.CAPTURE_FULL_URL + CAPTURE_URL) : links.CAPTURE_URL),
                                  search, isSkinAid())
                              }
                            }}>
                            {!(location.pathname.includes("capture")) ? <SkinScannerIconNew /> : <SkinScannerActiveIconNew />}
                          </IconButton>
                        </Box>
                      </Box>

                      {/* MENU */}
                      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "2px" }}>
                        <IconButton
                          style={{ color: neutralblack, borderRadius: 10 }}
                          sx={{ width: "48px", height: "48px" }}
                          onClick={() => { setOpenMenu(s => !s); setOpenUser(false) }}>
                          <MenuIcon />
                        </IconButton>
                      </Box>
                    </Box>


                    {openUser && <Box ref={topMenuRef} sx={{ width: `${EXTENDED_DRAWER_SIZE}px`, position: "fixed", top: `${DRAWER_SIZE}px`, left: "0px", backgroundColor: neutral80 }}>
                      {/* PROFILE */}
                      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "2px" }}>
                        <IconButton
                          disabled={isDemo()}
                          style={{ color: isDemo() ? neutral50 : neutralblack, borderRadius: 10 }}
                          sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                          onClick={() => { setOpenUser(false); history.push(links.SETTINGS_URL) }}>
                          <AccountCircleIcon />
                        </IconButton>
                        <Typography sx={{ fontSize: '11px' }}>{PROFILE_LOWERCASE}</Typography>
                      </Box>

                      {/* LOGOUT */
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "2px" }}>
                          <IconButton
                            disabled={isDemo()}
                            style={{ color: isDemo() ? neutral50 : neutralblack, borderRadius: 10 }}
                            sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                            onClick={() => { setOpenUser(false); logout(dispatch, session); }}>
                            <LogoutIcon />
                          </IconButton>
                          <Typography sx={{ fontSize: '11px' }}>{LOGOUT_LOWERCASE}</Typography>
                        </Box>}
                    </Box>}

                    {openMenu && <Box sx={{ width: `${EXTENDED_DRAWER_SIZE}px`, position: "fixed", bottom: `${DRAWER_SIZE}px`, left: "0px", backgroundColor: neutral80 }}>
                      {/* ABOUT */}
                      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "2px" }}>
                        <IconButton
                          style={{ color: neutralblack, borderRadius: 10 }}
                          sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                          onClick={() => { setOpenMenu(false); history.push(links.ABOUT_URL) }}>
                          <InfoIcon />
                        </IconButton>
                        <Typography sx={{ fontSize: '11px' }}>{ABOUT_LOWERCASE}</Typography>
                      </Box>

                      {/* Feedback */}
                      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "2px" }}>
                        <IconButton
                          style={{ color: neutralblack, borderRadius: 10 }}
                          sx={{ width: "32px", height: "32px" }}
                          onClick={onFeedBack}>
                          <FeedbackIcon color={neutralblack} />
                        </IconButton>
                        <Typography sx={{ fontSize: '11px' }}>{FEEDBACK.toLocaleLowerCase()}</Typography>
                      </Box>


                      {/* SUPPORT */
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "2px" }}>
                          <IconButton
                            style={{ color: neutralblack, borderRadius: 10 }}
                            sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                            onClick={() => { setOpenMenu(false); window.open(DERMUS_WIKI_LINK, "_blank"); }}>
                            <HelpIcon />
                          </IconButton>
                          <Typography sx={{ fontSize: '11px' }}>{SUPPORT_LOWERCASE}</Typography>
                        </Box>}

                      {/* PREFERENCES */}
                      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "2px" }}>
                        <IconButton
                          disabled={isDemo()}
                          style={{ color: isDemo() ? neutral50 : neutralblack, borderRadius: 10 }}
                          sx={{ width: "32px", height: "32px" }}
                          onClick={() => { setOpenMenu(false); history.push(links.USER_PREFERENCES_URL) }}>
                          <PreferencesIcon />
                        </IconButton>
                        <Typography sx={{ fontSize: '11px' }}>{PREFERENCES_LOWERCASE}</Typography>
                      </Box>

                      {/* FULLSCREEN*/}
                      {displayMode !== FULLSCREEN_STRING && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "2px" }}>
                        <IconButton
                          style={{ color: neutralblack, borderRadius: 10 }}
                          sx={{ width: "32px", height: "32px" }}
                          onClick={() => { setOpenMenu(false); handleFullScreen() }}>
                          {isFullscreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
                        </IconButton>
                        <Typography sx={{ fontSize: '11px' }} noWrap>{FULLSCREEN_LOWERCASE}</Typography>
                      </Box>}

                    </Box>}
                  </Box>
                </ClickAwayListener>
                :
                < ClickAwayListener onClickAway={() => { setOpenMenu(false); setOpenUser(false) }}>
                  {/* NEW LAYOUT FOR PORTRAIT MODE*/}
                  <Drawer
                    sx={{ flexShrink: 0, '& .MuiDrawer-paper': { height: openMenu || openUser ? DRAWER_SIZE + 96 : DRAWER_SIZE, boxSizing: 'border-box', } }}
                    variant="permanent"
                    anchor="bottom"
                  >

                    {/* MENU ITEMS */}
                    {openMenu &&
                      <Box sx={{ backgroundColor: neutral80 }}>
                        {/* PULLER*/}
                        <Box
                          sx={{
                            width: 30,
                            height: 6,
                            backgroundColor: neutral70,
                            borderRadius: 3,
                            position: 'absolute',
                            top: 8,
                            left: 'calc(50% - 15px)'
                          }}>
                        </Box>
                        <Box sx={{ justifyContent: 'space-between', display: "flex", flexDirection: "row", alignItems: "center", height: 96, paddingLeft: "5px", paddingRight: "5px" }}>

                          {/* ABOUT */}
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <IconButton
                              style={{ color: neutralblack, borderRadius: 10 }}
                              sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                              onClick={() => { setOpenMenu(false); history.push(links.ABOUT_URL) }}>
                              <InfoIcon />
                            </IconButton>
                            <Typography sx={{ fontSize: '11px' }}>{ABOUT_LOWERCASE}</Typography>
                          </Box>
                          {/* Feedback */}
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <IconButton
                              style={{ color: neutralblack, borderRadius: 10 }}
                              sx={{ width: "32px", height: "32px" }}
                              onClick={onFeedBack}>
                              <FeedbackIcon />
                            </IconButton>
                            <Typography sx={{ fontSize: '11px' }}>{FEEDBACK.toLocaleLowerCase()}</Typography>
                          </Box>

                          {/* SUPPORT */
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <IconButton
                                style={{ color: neutralblack, borderRadius: 10 }}
                                sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                                onClick={() => { setOpenMenu(false); window.open(DERMUS_WIKI_LINK, "_blank"); }}>
                                <HelpIcon />
                              </IconButton>
                              <Typography sx={{ fontSize: '11px' }}>{SUPPORT_LOWERCASE}</Typography>
                            </Box>}

                          {/* PREFERENCES */}
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <IconButton
                              disabled={isDemo()}
                              style={{ color: isDemo() ? neutral50 : neutralblack, borderRadius: 10 }}
                              sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                              onClick={() => { setOpenMenu(false); history.push(links.USER_PREFERENCES_URL) }}>
                              <PreferencesIcon />
                            </IconButton>
                            <Typography sx={{ fontSize: '11px' }}>{PREFERENCES_LOWERCASE}</Typography>
                          </Box>

                          {/* FULLSCREEN*/}
                          {displayMode !== FULLSCREEN_STRING && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <IconButton
                              style={{ color: neutralblack, borderRadius: 10 }}
                              sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                              onClick={() => { setOpenMenu(false); handleFullScreen() }}>
                              {isFullscreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
                            </IconButton>
                            <Typography sx={{ fontSize: '11px' }} noWrap>{FULLSCREEN_LOWERCASE}</Typography>
                          </Box>}
                        </Box>
                      </Box>}

                    {/* USER ITEMS */}
                    {openUser &&
                      <Box sx={{ backgroundColor: neutral80 }}>
                        {/* PULLER*/}
                        <Box
                          sx={{
                            width: 30,
                            height: 6,
                            backgroundColor: neutral70,
                            borderRadius: 3,
                            position: 'absolute',
                            top: 8,
                            left: 'calc(50% - 15px)'
                          }}>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: 96 }}>

                          {/* PROFILE */}
                          <Box sx={{ display: "flex", flexDirection: "column", width: "25%", alignItems: "center" }}>
                            <IconButton
                              disabled={isDemo()}
                              style={{ color: isDemo() ? neutral50 : neutralblack, borderRadius: 10 }}
                              sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                              onClick={() => { setOpenUser(false); history.push(links.SETTINGS_URL) }}>
                              <AccountCircleIcon />
                            </IconButton>
                            <Typography sx={{ fontSize: '11px' }}>{PROFILE_LOWERCASE}</Typography>
                          </Box>

                          {/* logout */
                            <Box sx={{ display: "flex", flexDirection: "column", width: "25%", alignItems: "center" }}>
                              <IconButton
                                disabled={isDemo()}
                                style={{ color: isDemo() ? neutral50 : neutralblack, borderRadius: 10 }}
                                sx={{ width: "32px", height: "32px", display: "flex", flexDirection: "column" }}
                                onClick={() => { setOpenUser(false); logout(dispatch, session); }}>
                                <LogoutIcon />
                              </IconButton>
                              <Typography sx={{ fontSize: '11px' }}>{LOGOUT_LOWERCASE}</Typography>
                            </Box>}
                        </Box>
                      </Box>}

                    {/* DRAWER BACKGROUND */}
                    <Box bgcolor={neutral80} sx={{ width: "100%", height: DRAWER_SIZE, justifyContent: 'space-between', display: "flex", flexDirection: "row", alignItems: "center" }}>

                      {/* ACCOUNT MENU */}
                      <Box sx={{ display: "flex", flexDirection: "column", width: "25%", alignItems: "center" }}>
                        <IconButton
                          style={{ color: neutralblack, borderRadius: 50 }}
                          sx={{ width: "48px", height: "48px" }}
                          onClick={() => { setOpenUser(s => !s); setOpenMenu(false); }}>
                          <Avatar sx={{ bgcolor: tertiary95, color: neutralblack, width: 42, height: 42, fontSize: "16px" }}>{monogram}</Avatar>
                        </IconButton>
                      </Box>

                      {/* PATIENTS */}
                      <Box sx={{ display: "flex", flexDirection: "column", width: "25%", alignItems: "center" }}>
                        <IconButton style={{
                          backgroundColor: isSkinAid() && (location?.pathname === "/" || location.pathname.includes("addpatient") || location.pathname.includes("editpatient") || patientId) ? secondary90 : null,
                          color: neutralblack, borderRadius: 10
                        }}
                          sx={{ width: "48px", height: "48px" }}
                          onClick={() => {
                            setOpenMenu(false); setOpenUser(false);
                            handleIconClick(isSkinAid() ? "/" : links.SKINAID_FULL_URL,
                              isSkinAid() ? "" : `?${buildSearchQuery({ userToken: generateToken({ user: session.getIdToken().payload.sub }) })}`,
                              !isSkinAid())
                          }}>
                          {(isSkinAid() && (location?.pathname === "/" || location.pathname.includes("addpatient") || location.pathname.includes("editpatient") || patientId))
                            ? <SkinAidActiveIconNew /> : < SkinAidIconNew />}
                        </IconButton>
                      </Box>

                      {/* CAPTURE */}
                      <Box sx={{ display: "flex", flexDirection: "column", width: "25%", alignItems: "center" }}>
                        <IconButton style={{
                          backgroundColor: location.pathname.includes("capture") ? secondary90 : null,
                          color: neutralblack, borderRadius: 10
                        }}
                          sx={{ width: "48px", height: "48px" }}
                          onClick={() => {
                            setOpenMenu(false); setOpenUser(false)
                            if (isSkinAid() && !showToCaptureTip &&
                              (((patientId && !location.pathname.includes("addpatient"))) ||      // patient level
                                (lesionLocalId && !location.pathname.includes("addlesion")))      // lesion level
                            ) {
                              //Showing dialog to user to select
                              setShowSelectCaptureDialog(true)
                            } else {
                              let search = null
                              if (patientId && lesionLocalId && !location.pathname.includes("addlesion") && startCaptureFromCurrentLevel) {
                                if (isSkinAid()) {
                                  search = `?${buildSearchQuery({ patient: patientId, lesion: lesionLocalId, userToken: generateToken({ user: session.getIdToken().payload.sub }) })}`
                                } else {
                                  search = `?${buildSearchQuery({ patient: patientId, lesion: lesionLocalId })}`
                                }
                              } else if (patientId && !location.pathname.includes("addpatient") && startCaptureFromCurrentLevel) {
                                if (isSkinAid()) {
                                  search = `?${buildSearchQuery({ patient: patientId, userToken: generateToken({ user: session.getIdToken().payload.sub }) })}`
                                } else {
                                  search = `?${buildSearchQuery({ patient: patientId, })}`
                                }
                              } else {
                                if (isSkinAid()) {
                                  search = `?${buildSearchQuery({ userToken: generateToken({ user: session.getIdToken().payload.sub }) })}`
                                }
                              }
                              //Not showing the dialog
                              handleIconClick((isSkinAid() ? (links.CAPTURE_FULL_URL + CAPTURE_URL) : links.CAPTURE_URL),
                                search, isSkinAid())
                            }
                          }}>
                          {!(location.pathname.includes("capture")) ? <SkinScannerIconNew /> : <SkinScannerActiveIconNew />}
                        </IconButton>
                      </Box>


                      {/* MENU */}
                      <Box sx={{ display: "flex", flexDirection: "column", width: "25%", alignItems: "center" }}>
                        <IconButton
                          style={{ color: neutralblack, borderRadius: 10 }} sx={{ width: "48px", height: "48px" }}
                          onClick={() => { setOpenMenu(s => !s); setOpenUser(false) }}>
                          <MenuIcon />
                        </IconButton>
                      </Box>
                    </Box>


                  </Drawer >
                </ClickAwayListener>
              }


              <Box sx={windowSize.width >= MOBILE_MODE ? {
                position: "fixed",
                top: 0,
                left: EXTENDED_DRAWER_SIZE,
                width: `calc(100% - ${EXTENDED_DRAWER_SIZE}px)`,
                overflowY: "auto",
                height: "100%",
              } : {
                position: "fixed",
                top: 0,
                width: windowSize.width,
                overflowY: "auto",
                height: `calc(100% - ${DRAWER_SIZE}px)`
              }
              }> {/* NEXT TO DRAWER */}
                {showSelectCaptureDialog && <MoveToCaptureDialog
                  setShowSelectCaptureDialog={setShowSelectCaptureDialog}
                  userPreferences={userPreferences}
                  showToCaptureTip={showToCaptureTip}
                  setShowToCaptureTip={setShowToCaptureTip}
                  setStartCaptureFromCurrentLevel={setStartCaptureFromCurrentLevel}
                  handleIconClick={handleIconClick}
                  location={location}
                  patientId={patientId}
                  lesionLocalId={lesionLocalId}
                  user={session.getIdToken().payload.sub}
                />}

                {/* MAIN SCREEN */}
                {props.children}
              </Box>
            </Box>
        }

      </main >
    </ErrorBoundary >
  );
};

export default Layout;
