import * as actionType from "../actions/patientManagementActions";

const initState = {
  capture: null,
  patient: null,
  record: null,
  importedOptImg: null,
  lesion: null,
  stale: false,
  view: null,
  manifest: {},
  recordListOfLesion: undefined,
  recordIdToDisplay: null,
  fwToUpdate: null,
  deviceInfo: null
};

const patientManagementReducer = (state = initState, action) => {
  switch (action.type) {
    case actionType.MANIFEST:
      return {
        ...state,
        manifest: action.payload.manifest,
      };
    case actionType.FW_TO_UPDATE:
      return {
        ...state,
        fwToUpdate: action.payload.fwToUpdate,
      };
    case actionType.DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.payload.deviceInfo,
      };
    case actionType.DEVICE_SERIAL_NUMBER:
      return {
        ...state,
        deviceSerialNumber: action.payload.serialnumber,
      };
    case actionType.VIEW:
      return {
        ...state,
        view: action.payload.view,
      };
    case actionType.SET_CAPTURE_INFO:
      return {
        ...state,
        capture: action.payload.capture,
      };
    case actionType.SET_PATIENT_INFO:
      return {
        ...state,
        patient: (action.payload.patient === null ? null : { ...action.payload.patient }),
      };
    case actionType.RECORD_ID_TO_DISPLAY:
      return {
        ...state,
        recordIdToDisplay: { ...action.payload.recordIdToDisplay },
      };
    case actionType.SET_RECORDS_LIST_OF_LESION:
      if (action.payload.recordList?.lesionId === null || action.payload.recordList?.records === null) {
        return {
          ...state,
          recordListOfLesion: undefined,
        };
      }
      return {
        ...state,
        recordListOfLesion: { ...action.payload.recordList },
      };

    case actionType.MODIFY_RECORDS_LIST_OF_LESION:
      if (state?.recordListOfLesion === null || state?.recordListOfLesion === undefined ||
        state?.recordListOfLesion?.records === null || state?.recordListOfLesion?.records === undefined ||
        state?.recordListOfLesion?.lesionId === null || state?.recordListOfLesion?.lesionId === undefined) {
        return state
      }
      const newState = { ...state }
      newState.recordListOfLesion?.records?.forEach((item, index) => {
        if (item.id === action.payload.record.id) {
          newState.recordListOfLesion.records[index] = action.payload.record
        }
      })
      return {
        ...state,
        recordListOfLesion: { ...state.recordListOfLesion, records: newState.recordListOfLesion.records },
      };
    case actionType.SET_RECORD_INFO:
      return {
        ...state,
        record: action.payload.record ? { ...action.payload.record } : null,
      };
    case actionType.SET_LESION_INFO:
      return {
        ...state,
        lesion: action.payload.lesion ? { ...action.payload.lesion } : null
      };
    case actionType.SET_NOTES_TO_RECORD:
      const record = { ...state.record, notes: [...action.payload.notes] };
      return {
        ...state,
        record,
      };
    case actionType.SET_NOTE_TO_LESION:
      const lesion = { ...state.lesion };
      lesion.note = action.payload.note.slice();
      return {
        ...state,
        lesion,
      };
    case actionType.SET_DIAGNOSIS_TO_LESION:
      const lesionToDiagnosis = { ...state.lesion, ...action.payload.diagnosis };
      return {
        ...state,
        lesion: lesionToDiagnosis,
      };
    case actionType.SERVE_FROM_STALE:
      return { ...state, stale: action.payload.stale };
    default:
      return state;
  }
};

export default patientManagementReducer;
