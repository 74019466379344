import Annotations from "./Annotations";
import FreeTextNote from "./FreeTextNote";
import Measurement from "./Measurement";
import VolumeEstimation from "./VolumeEstimation";

class Record {
  /**
   * Constructor
   * @param {String} date date of exam
   * @param {String} date raw date
   * @param {String} sinceExamDate time since last exam
   * @param {String} lesionId lesion id
   * @param {Note} notes notes
   * @param {String} optical optical image
   * @param {String} opticalThumbnail optical image thumbnail
   * @param {Object} opticalUrl optical url
   * @param {String} ultrasound ultrasound image
   * @param {String} ultrasoundThumbnail ultrasound thumbanil
   * @param {Object} ultrasoundUrl ultrasound url
   * @param {String} id id
   * @param {String} device_name device_name
   * @param {String} device_id device_id
   * @param {Enumerator} record_type record_type
   * @param {String} source_record soruce_record
   * @param {Segmentation} segmentation segmentation
   */
  constructor(
    date,
    rawDate,
    sinceExamDate,
    lesionId,
    notes,
    optical,
    opticalThumbnail,
    opticalUrl,
    ultrasound,
    ultrasoundThumbnail,
    ultrasoundUrl,
    clinical,
    clinicalThumbnail,
    clinicalUrl,
    dermoscopy,
    dermoscopyThumbnail,
    dermoscopyUrl,
    id,
    local_id,
    device_id,
    device_name,
    record_type,
    source_record,
    segmentation,
    annotations,
    measurements,
    usOptVolumeEstimation,
    free_texts,
    us_metadata,
    opt_metadata,
    opticalGetUrls,
    usGetUrls,
    clinicalGetUrl,
    dermoscopyGetUrl,
    usThumbnailUrl,
    opticalThumbnailUrl,
    favourite,
  ) {
    this.examDate = date;
    this.rawDate = rawDate;
    this.sinceExamDate = sinceExamDate;
    this.lesionId = lesionId;
    this.notes = notes;
    this.opticalImId = optical;
    this.opticalThumbnailId = opticalThumbnail;
    this.opticalUrl = opticalUrl;
    this.usImId = ultrasound;
    this.usThumbnailId = ultrasoundThumbnail;
    this.usUrl = ultrasoundUrl;
    this.clinical = clinical;
    this.clinicalThumbnail = clinicalThumbnail;
    this.clinicalUrl = clinicalUrl;


    this.dermoscopy = dermoscopy;
    this.dermoscopyThumbnail = dermoscopyThumbnail;
    this.dermoscopyUrl = dermoscopyUrl

    this.id = id;
    this.local_id = local_id
    this.device_name = device_name;
    this.device_id = device_id;
    this.record_type = record_type;
    this.source_record = source_record;
    this.segmentation = segmentation;

    this.optAnnotation = {}
    annotations?.OPT?.forEach(element => {
      this.optAnnotation[element.frame] = new Annotations(element)
    });
    this.usAnnotation = {}
    annotations?.US?.forEach(element => {
      this.usAnnotation[element.frame] = new Annotations(element)
    });
    this.clinicalAnnotation = {}
    annotations?.CLINICAL?.forEach(element => {

      this.clinicalAnnotation[element.frame] = new Annotations(element)
    });
    this.dermoscopyAnnotation = {}
    annotations?.DERMOSCOPY?.forEach(element => {
      this.dermoscopyAnnotation[element.frame] = new Annotations(element)
    });


    this.optMeasurement = {}
    measurements?.OPT?.forEach(element => {
      this.optMeasurement[element.frame] = new Measurement(element)
    });
    this.usMeasurement = {}
    measurements?.US?.forEach(element => {
      this.usMeasurement[element.frame] = new Measurement(element)
    });
    this.clinicalMeasurement = {}
    measurements?.CLINICAL?.forEach(element => {
      this.clinicalMeasurement[element.frame] = new Measurement(element)
    });
    this.dermoscopyMeasurement = {}
    measurements?.DERMOSCOPY?.forEach(element => {
      this.dermoscopyMeasurement[element.frame] = new Measurement(element)
    });

    this.usOptVolumeEstimation = {}
    usOptVolumeEstimation?.US_OPT?.forEach(element => {
      this.usOptVolumeEstimation[element.frame] = new VolumeEstimation(element)
    });

    this.optFreeText = {}
    free_texts?.OPT?.forEach(element => {
      this.optFreeText[element.frame] = new FreeTextNote(element)
    });
    this.usFreeText = {}
    free_texts?.US?.forEach(element => {
      this.usFreeText[element.frame] = new FreeTextNote(element)
    });
    this.clinicalFreeText = {}
    free_texts?.CLINICAL?.forEach(element => {
      this.clinicalFreeText[element.frame] = new FreeTextNote(element)
    });
    this.dermoscopyFreeText = {}
    free_texts?.DERMOSCOPY?.forEach(element => {
      this.dermoscopyFreeText[element.frame] = new FreeTextNote(element)
    });

    this.usMetadata = us_metadata
    this.optMetadata = opt_metadata

    if (this.usMetadata?.px_per_mm) {//régen rosszul volt
      this.usMetadata.mm_per_px = this.usMetadata.px_per_mm
    }

    if (this.optMetadata?.px_per_mm) {
      this.optMetadata.mm_per_px = this.optMetadata.px_per_mm
    }

    this.optGetUrls = opticalGetUrls;
    this.usGetUrls = usGetUrls;


    this.clinicalGetUrl = clinicalGetUrl;
    this.dermoscopyGetUrl = dermoscopyGetUrl;


    this.usThumbnailUrl = usThumbnailUrl;
    this.opticalThumbnailUrl = opticalThumbnailUrl;

    this.favourite = favourite;
  }
}
export default Record;
