export const SET_CAPTURE_INFO = "SET_CAPTURE_INFO";
export const SET_PATIENT_INFO = "SET_PATIENT_INFO";
export const SET_RECORDS_LIST_OF_LESION = "SET_RECORDS_LIST_OF_LESION";
export const MODIFY_RECORDS_LIST_OF_LESION = "MODIFY_RECORDS_LIST_OF_LESION";
export const RECORD_ID_TO_DISPLAY = "RECORD_ID_TO_DISPLAY";
export const SET_RECORD_INFO = "SET_RECORD_INFO";
export const SET_LESION_INFO = "SET_LESION_INFO";
export const SET_NOTES_TO_RECORD = "SET_NOTES_TO_RECORD";
export const SET_NOTE_TO_LESION = "SET_NOTE_TO_LESION";
export const SET_DIAGNOSIS_TO_LESION = "SET_DIAGNOSIS_TO_LESION";
export const SERVE_FROM_STALE = "SERVE_FROM_STALE";
export const VIEW = "VIEW";
export const MANIFEST = "MANIFEST";
export const DEVICE_SERIAL_NUMBER = "DEVICE_SERIAL_NUMBER";
export const FW_TO_UPDATE = "FW_TO_UPDATE";
export const DEVICE_INFO = "DEVICE_INFO";
