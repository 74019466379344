import bodyPatientFront from "../../assets/images/body_diagram_front.png";
import bodyPatientBack from "../../assets/images/body_diagram_back.png";


import bodyAnimalFront from "../../assets/images/animal_patient_front.png";
import bodyAnimalBack from "../../assets/images/animal_patient_back.png";

import {
  COLORMAP_NAME,
  COLORMAP_SHADES,
  PATIENT_TYPE,
  SEGMENTATION_LAYERS,
  SEGMENTATION_LAYER_COLORS,
  SEGMENTATION_LAYER_ENUM,
} from "../Constants/Constants";
import { arrayMinMax } from "./utility";

import colormap from "colormap";
import { error40, neutral90, neutralblack, neutralwhite } from "../../Themes/dermusTheme";
import { ECHO_INTENSITY_LOWER_CASE, HIGH, LOW } from "../Constants/Message";
import { drawTriangleGeneral } from "./imageDimension";
import { relativeToAbolutePointPosition } from "../Imaging/useImage_v2";

export const SEGMENTATIONL_LABEL_CONFIG = {
  font: 70,
  horizontal_padding: 10,
  vertical_padding: 5,
  circle_radius_ratio: 3
}

export const ANNOTATION_POINT_CONFIG = {
  stickWidthWhite: 8,
  stickWidthBlack: 3,
  length: 30,
  radius: 25
}

export const DRAW_CONFIG = {
  ZOOM_SIZE: 600,
  LINE_WIDTH: 15,
  CROSS_SIZE: 45,
  CROSS_COLOR: error40,
  POINT_COLOR: "#16a112",
  POINT_WIDTH: 2,
  POINT_RADIUS: 2,
  DOT_SIZE: 10,
  DOT_COLOR: error40,
};

export const POINT_CONFIG = {
  LINE_WIDTH: 2,
  POINT_COLOR: "#16a112",
  POINT_WIDTH: 2,
  POINT_RADIUS: 2,
  CIRCLE_RADIUS: 10,
};

export const HEADER_HEIGHT = 100
const HEADER_ROW_HEIGHT = 24
const HEADER_RIGHT_PADDING = 150

export const rgbToHex = ({ r, g, b }) => {
  return "#" + r.toString(16) + g.toString(16) + b.toString(16);
}
const calculateRatioValue = (defaultValue, width) => {
  return Math.max(
    (width / 300) * defaultValue,
    defaultValue
  );
}


export const frontPatientBodyImage = new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener("load", () => {
    return resolve(image);
  });
  image.addEventListener("error", (error) => reject(error));
  image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
  image.src = bodyPatientFront;
})
export const backPatientBodyImage = new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener("load", () => {
    return resolve(image);
  });
  image.addEventListener("error", (error) => reject(error));
  image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
  image.src = bodyPatientBack;
})

export const frontAnimalBodyImage = new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener("load", () => {
    return resolve(image);
  });
  image.addEventListener("error", (error) => reject(error));
  image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
  image.src = bodyAnimalFront;
})
export const backAnimalBodyImage = new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener("load", () => {
    return resolve(image);
  });
  image.addEventListener("error", (error) => reject(error));
  image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
  image.src = bodyAnimalBack;
})


const drawImageOnCanvas = async (bckImage, ctx, coordX, coordY, zoomSize) => {
  const image = await bckImage
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.drawImage(
    image,
    Math.min(Math.max(0, coordX - zoomSize / 2), image.width - zoomSize),
    Math.min(Math.max(0, coordY - zoomSize / 2), image.height - zoomSize),
    zoomSize,
    zoomSize,
    0,
    0,
    ctx.canvas.width,
    ctx.canvas.height
  );
  return image
}


export const drawLine = (
  ctx,
  pointStart,
  pointEnd,
  color = POINT_CONFIG.POINT_COLOR,
  lineWidth = POINT_CONFIG.LINE_WIDTH
) => {
  ctx.lineWidth = lineWidth;
  ctx.beginPath();
  ctx.moveTo(pointStart.x, pointStart.y);
  ctx.lineTo(pointEnd.x, pointEnd.y);
  ctx.closePath();

  ctx.strokeStyle = color;
  ctx.stroke();
};

/**
 * Draw a cros
 * @param {Canvas} canvas - canvas to draw
 * @param {Number} x - cross x position
 * @param {Number} y - cross y position
 * @param {Object} config - draw config {LINE_WIDTH: number, CROSS_SIZE: number, CROSS_COLOR: color}
}
 */
export const drawCross = (x, y, ctx, config = DRAW_CONFIG) => {
  ctx.save();
  ctx.lineWidth = config.LINE_WIDTH;
  ctx.beginPath();
  ctx.moveTo(x - config.CROSS_SIZE,
    y - config.CROSS_SIZE
  );
  ctx.lineTo(
    x + config.CROSS_SIZE,
    y + config.CROSS_SIZE
  );
  ctx.moveTo(
    x - config.CROSS_SIZE,
    y + config.CROSS_SIZE
  );
  ctx.lineTo(
    x + config.CROSS_SIZE,
    y - config.CROSS_SIZE
  );
  ctx.closePath();

  ctx.strokeStyle = config.CROSS_COLOR;
  ctx.stroke();
  ctx.restore();
};


/**
 * Draw a dot
 * @param {Canvas} canvas - canvas to draw
 * @param {Number} x - cross x position
 * @param {Number} y - cross y position
 * @param {Object} config - draw config {DOT_SIZE: number, DOT_COLOR: color}
}
 */
export const drawDot = (x, y, ctx, config = DRAW_CONFIG) => {
  ctx.save();
  ctx.beginPath();
  ctx.arc(x, y, config.DOT_SIZE, 0, 2 * Math.PI, true);
  ctx.closePath();
  ctx.fillStyle = config.DOT_COLOR;
  ctx.fill();
  ctx.restore();
};


/**
 * Draw a plus sign
 * @param {Canvas} canvas - canvas to draw
 * @param {Number} x - cross x position
 * @param {Number} y - cross y position
 * @param {Object} config - draw config {LINE_WIDTH: number, CROSS_SIZE: number, CROSS_COLOR: color}
}
 */
export const drawPlusSign = (x, y, ctx, config = DRAW_CONFIG) => {

  x = Math.round(x * ctx.canvas.width)
  y = Math.round(y * ctx.canvas.height)

  ctx.save();
  ctx.lineWidth = config.LINE_WIDTH;
  ctx.beginPath();
  ctx.moveTo(x - config.CROSS_SIZE, y);
  ctx.lineTo(x + config.CROSS_SIZE, y);

  ctx.moveTo(x, y - config.CROSS_SIZE);
  ctx.lineTo(x, y + config.CROSS_SIZE);
  ctx.closePath();

  ctx.strokeStyle = config.CROSS_COLOR;
  ctx.stroke();
  ctx.restore();
};


export const drawWaterMarkHDHRMode = (ctx, text, size = 24, padding = 50, width = 40, height = 30) => {
  const { fontHeight } = getMeasurementBoxHeight(document.createElement("canvas").getContext("2d"), 0, size)
  ctx.save();
  ctx.beginPath();

  //Inside filled rectangle
  ctx.fillStyle = "rgba(255, 255, 255, 1)";
  ctx.rect(padding - 5, padding - 5, width, height);

  //Border rectangle
  ctx.strokeStyle = "rgba(0, 0, 0, 1)";
  ctx.lineWidth = 8;
  ctx.strokeRect(padding - 5, padding - 5, width, height);
  ctx.fill();

  //Text
  ctx.font = `bold ${size}px Karla`;
  ctx.fillStyle = "rgba(0, 0, 0, 1)"
  drawMultilineText(ctx, text, padding, padding, ctx.canvas.width - 2 * padding, 3, fontHeight)
}

export const drawWaterMark = (ctx, text, size = 24, padding = 50, xPos, yPos) => {
  const { fontHeight } = getMeasurementBoxHeight(document.createElement("canvas").getContext("2d"), 0, size)
  ctx.font = `${size}px Karla`;
  ctx.fillStyle = "rgba(255, 255, 255, 0.5)"
  drawMultilineText(ctx, text, xPos ?? padding, yPos ?? padding, ctx.canvas.width - 2 * padding, 3, fontHeight)
}

/**
 * Draw header on image
 * @param {*} canvas 
 * @param {*} patientName 
 * @param {*} patientInsuranceNumber 
 * @param {*} rawDate 
 * @returns canvas with header
 */
export const drawHeaderOnImage = (ctx, canvasWidth, patientName = null, patientInsuranceNumber = null, rawDate) => {

  ctx.fillStyle = "black";
  ctx.fillRect(0, 0, canvasWidth, HEADER_HEIGHT);
  patientName && drawWaterMark(ctx, patientName, 24, 50, HEADER_ROW_HEIGHT, HEADER_ROW_HEIGHT)
  patientInsuranceNumber && drawWaterMark(ctx, patientInsuranceNumber, 24, 50, HEADER_ROW_HEIGHT, 2 * HEADER_ROW_HEIGHT)

  let fields = rawDate.split(' ');
  if (fields.length === 2) {
    drawWaterMark(ctx, fields[0], 24, 50, canvasWidth - HEADER_RIGHT_PADDING, HEADER_ROW_HEIGHT)
    drawWaterMark(ctx, fields[1], 24, 50, canvasWidth - HEADER_RIGHT_PADDING, 2 * HEADER_ROW_HEIGHT)
  }

  return ctx;
}


export const drawAnnotationPoint = (xCross,
  yCross, ctx, config, onlyPoint = false, lineWidth = null, radius = null, radiusIn = null) => {
  if (!config.color) {
    config.color = POINT_CONFIG.POINT_COLOR
  }
  drawFilledCircle(
    xCross,
    yCross,
    ctx,
    config,
    lineWidth,
    radiusIn
  );
  if (!onlyPoint) {
    drawCircle(
      xCross,
      yCross,
      ctx,
      config.color,
      lineWidth,
      radius
    );
  }
}


export const drawAnnotationPoint_v2 = (ctx, x, y, char, withStick = true, inverted = false, config = ANNOTATION_POINT_CONFIG) => {
  ctx.save();
  const { stickWidthWhite, stickWidthBlack, length, radius, font } = config
  const stickOffset = Math.floor((stickWidthWhite - stickWidthBlack) / 2);
  const centerOffset = withStick ? (length + radius) / Math.sqrt(2) : 0;
  if (withStick) {
    lineToAngle(ctx, x, y, length, 45, inverted ? neutralblack : neutralwhite, stickWidthWhite);
    lineToAngle(ctx, x + stickOffset, y - stickOffset, length, 45, !inverted ? neutralblack : neutralwhite, stickWidthBlack);
  }
  drawFilledCircle(x + centerOffset, y - centerOffset, ctx, { color: inverted ? neutralblack : neutralwhite }, null, radius);
  drawFilledCircle(x + centerOffset, y - centerOffset, ctx, { color: !inverted ? neutralblack : neutralwhite }, null, radius - stickOffset);

  ctx.fillStyle = inverted ? neutralblack : neutralwhite;
  ctx.textBaseline = "middle";
  ctx.textAlign = "center";
  ctx.font = `${font}px Karla`;

  ctx.fillText(char, x + centerOffset, y - centerOffset);
  ctx.restore();
}

export const drawMeasurementPointRect = (xCross,
  yCross, ctx, config, size) => {
  if (!config.color) {
    config.color = POINT_CONFIG.POINT_COLOR
  }
  ctx.fillStyle = config.color;
  ctx.fillRect(xCross - size / 2, yCross - size / 2, size, size)
}
export const drawMeasurementPointTriangle = (xCross,
  yCross, ctx, config, angle) => {
  if (!config.color) {
    config.color = POINT_CONFIG.POINT_COLOR
  }
  drawTriangleGeneral(ctx, { x: xCross, y: yCross }, angle, config.color)
}
export const drawMeasurementPointCircle = (xCross,
  yCross, ctx, config, diameter) => {
  if (!config.color) {
    config.color = POINT_CONFIG.POINT_COLOR
  }
  ctx.fillStyle = config.color;
  ctx.beginPath();
  ctx.arc(xCross, yCross, diameter / 2, 0, 2 * Math.PI);
  ctx.fill();
}

/**
 * Draw filled cicrle
 * @param {Number} xCross x coord
 * @param {Number} yCross y coord
 * @param {Contex} ctx contex
 * @param {Number} color color
 * @param {Number} lineWidth line width
 * @param {Number} radius radius
 */
export const drawFilledCircle = (
  xCross,
  yCross,
  ctx,
  config,
  lineWidth,
  radius
) => {
  if (!lineWidth) {
    lineWidth = calculateRatioValue(POINT_CONFIG.LINE_WIDTH, ctx.canvas.width)
  }
  let color = config.color
  if (!color) {
    color = POINT_CONFIG.POINT_COLOR;
  }
  if (!radius) {
    radius = POINT_CONFIG.POINT_RADIUS// * (ctx.canvas.clientWidth / ctx.canvas.width)
  }

  ctx.beginPath();
  ctx.arc(xCross, yCross, radius, 0, 2 * Math.PI, false);
  ctx.fillStyle = color;
  ctx.fill();
};

/**
 * Draw cicrle
 * @param {Number} xCross x coord
 * @param {Number} yCross y coord
 * @param {Contex} ctx contex
 * @param {Number} color color
 * @param {Number} lineWidth line width
 * @param {Number} radius radius
 */
export const drawCircle = (xCross, yCross, ctx, color, lineWidth, radius) => {
  if (!color) {
    color = POINT_CONFIG.POINT_COLOR;
  }
  if (!radius) {
    radius = calculateRatioValue(POINT_CONFIG.CIRCLE_RADIUS, ctx.canvas.width)
  }
  if (!lineWidth) {
    lineWidth = calculateRatioValue(POINT_CONFIG.LINE_WIDTH, ctx.canvas.width)
  }

  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.beginPath();
  ctx.arc(xCross, yCross, radius, 0, 2 * Math.PI, false);
  ctx.stroke();
};



/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {Number} coordX - lesion location x coord
 * @param {Number} coordY - lesion location y coord
 * @param {String} returnType - blob or base64 (default blob)
 */
const getLesionLocationImage = async (coordX, coordY, patientType, returnType) => {
  const bckImage = coordX > 0 ? (patientType === PATIENT_TYPE.ANIMAL ? frontAnimalBodyImage : frontPatientBodyImage) : (patientType === PATIENT_TYPE.ANIMAL ? backAnimalBodyImage : backPatientBodyImage);
  let x = coordX > 0 ? coordX : -coordX;
  let y = coordY > 0 ? coordY : -coordY;
  const canvas = document.createElement("canvas");
  canvas.width = DRAW_CONFIG.ZOOM_SIZE;
  canvas.height = DRAW_CONFIG.ZOOM_SIZE;
  const ctx = canvas.getContext("2d");
  const image = await drawImageOnCanvas(
    bckImage,
    ctx,
    x,
    y,
    DRAW_CONFIG.ZOOM_SIZE
  );

  //% to px
  //x = x * image.width;
  //y = y * image.height;

  const zoomX = Math.min(
    Math.max(0, x - DRAW_CONFIG.ZOOM_SIZE / 2),
    image.width - DRAW_CONFIG.ZOOM_SIZE
  );
  const zoomY = Math.min(
    Math.max(0, y - DRAW_CONFIG.ZOOM_SIZE / 2),
    image.height - DRAW_CONFIG.ZOOM_SIZE
  );

  drawCross(x - zoomX, y - zoomY, ctx);

  if (returnType && returnType.localeCompare("base64") === 0) {
    return new Promise((resolve) => {
      resolve(canvas.toDataURL());
    });
  }
  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
};
/**
 * Draw depth map into image
 * @param {Uint8ClampedArray} imagePx image px
 * @param {Float32Array} overlayPx overlay px
 * @returns
 */
export const drawDepthMapOnToImage = (imagePx, overlayPx) => {
  let [minValue, maxValue] = arrayMinMax(overlayPx);

  let shadeRange = (maxValue - minValue) / (COLORMAP_SHADES - 1);
  if (shadeRange === 0) {
    shadeRange = 1;
  }
  let colors = colormap({
    colormap: COLORMAP_NAME,
    nshades: COLORMAP_SHADES,
    format: "rgb",
    alpha: 1,
  });
  overlayPx.forEach((px, ind) => {
    let mapInd = Math.round((px - minValue) / shadeRange);
    imagePx[ind * 4 + 0] = (imagePx[ind * 4 + 0] + colors[mapInd][0]) / 2;
    imagePx[ind * 4 + 1] = (imagePx[ind * 4 + 1] + colors[mapInd][1]) / 2;
    imagePx[ind * 4 + 2] = (imagePx[ind * 4 + 2] + colors[mapInd][2]) / 2;
  });
  return [imagePx, minValue, maxValue];
};



export const drawDeleteIconPath = (ctx, width, height, color = "white") => {
  ctx.strokeStyle = color;
  ctx.lineWidth = 1;
  ctx.fillStyle = color

  ctx.translate(width, height)
  var p = new Path2D("M15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29L15.5 4ZM8 21C6.9 21 6 20.1 6 19V9C6 7.9 6.9 7 8 7H16C17.1 7 18 7.9 18 9V19C18 20.1 17.1 21 16 21H8Z");
  ctx.fill(p);
  ctx.translate(-width, -height)
}

export const drawIcon = (ctx, img, x, y, w, h) => {
  ctx.drawImage(img, x * ctx.canvas.width, y * ctx.canvas.height, w * ctx.canvas.width, h * ctx.canvas.height);
}

export const createGdraient = (ctx, colormap, x, width) => {
  const gradient = ctx.createLinearGradient(x, 0, width, 0);
  if (colormap) {
    for (let ind = 0; ind < colormap.length; ind = ind + 3) {
      gradient.addColorStop(ind / colormap.length, `rgb(${colormap[ind] * 255}, ${colormap[ind + 1] * 255}, ${colormap[ind + 2] * 255})`)
    }
  } else {
    for (let ind = 0; ind < 256; ind++) {
      gradient.addColorStop(ind / 255, `rgb(${ind}, ${ind}, ${ind})`)
    }
  }
  return gradient
}


export const drawColorbarToImage = (ctx, gradient, x, y, width, height) => {
  ctx.fillStyle = gradient;
  ctx.fillRect(x, y, width, height);

  const padding = 5;
  ctx.fillStyle = "white";
  ctx.textBaseline = "bottom";
  ctx.font = "36px Karla";
  let textAlign = ctx.textAlign;
  ctx.textAlign = "start";
  ctx.fillText(LOW, x + padding, y - padding);
  ctx.textAlign = "center";
  ctx.fillText(ECHO_INTENSITY_LOWER_CASE, x + width / 2, y - padding);
  ctx.textAlign = "end";
  ctx.fillText(HIGH, x + width - padding, y - padding);
  ctx.textAlign = textAlign;
}



export const calcSegmentationLabelMetrics = (config = SEGMENTATIONL_LABEL_CONFIG) => {
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")
  const { font, horizontal_padding, vertical_padding } = config
  const above_skin = getMeasurementBoxHeight(ctx, horizontal_padding, font, SEGMENTATION_LAYERS.ABOVE_SKIN)
  const epidermis = getMeasurementBoxHeight(ctx, horizontal_padding, font, SEGMENTATION_LAYERS.EPIDERMIS)
  const lesion = getMeasurementBoxHeight(ctx, horizontal_padding, font, SEGMENTATION_LAYERS.LESION)
  const dermis = getMeasurementBoxHeight(ctx, horizontal_padding, font, SEGMENTATION_LAYERS.DERMIS)
  const subcutis = getMeasurementBoxHeight(ctx, horizontal_padding, font, SEGMENTATION_LAYERS.SUBCUTIS)
  const other = getMeasurementBoxHeight(ctx, horizontal_padding, font, SEGMENTATION_LAYERS.OTHER)

  const circle_radius = font / config.circle_radius_ratio
  const width = above_skin.width + epidermis.width + lesion.width + dermis.width + subcutis.width + other.width + 13 * horizontal_padding + 6 * (2 * circle_radius)
  const height = Math.max(above_skin.fontHeight, epidermis.fontHeight, lesion.fontHeight, dermis.fontHeight, subcutis.fontHeight, other.fontHeight) + 2 * vertical_padding

  return {
    width, height, horizontal_padding, vertical_padding,
    above_skin, epidermis, lesion, dermis, subcutis, other, font, circle_radius
  }
}

export const calcActLayerLabelMetrics = (activeLayer, text, config = SEGMENTATIONL_LABEL_CONFIG) => {
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")
  const { font, horizontal_padding, vertical_padding } = config

  const label = getMeasurementBoxHeight(ctx, horizontal_padding, font, SEGMENTATION_LAYERS[SEGMENTATION_LAYER_ENUM[activeLayer]])
  const string = getMeasurementBoxHeight(ctx, horizontal_padding, font, text)

  const circle_radius = font / config.circle_radius_ratio
  const width = label.width + string.width + 3 * horizontal_padding + (2 * circle_radius)
  const height = Math.max(string.fontHeight, label.fontHeight) + 2 * vertical_padding

  return {
    width, height, horizontal_padding, vertical_padding, font, circle_radius, label, string
  }
}
export const drawSegmentationLabelToImage = (ctx, x, y, targetWidth, targetHeight, metrics, config = { ...SEGMENTATIONL_LABEL_CONFIG }) => {
  const TEXT_COLOR = neutralblack


  if (targetWidth < metrics.width || targetHeight < metrics.height) {
    config.font = config.font - 1
    let newMetrics = calcSegmentationLabelMetrics(config)
    while (targetWidth < newMetrics.width || targetHeight < newMetrics.height) {
      config.font = config.font - 1
      if (config.font < 1) {
        break;
      }
      newMetrics = calcSegmentationLabelMetrics(config)
    }
    metrics = newMetrics
  }

  const { width, height, font, horizontal_padding, vertical_padding,
    above_skin, lesion, epidermis, dermis, subcutis, circle_radius, } = metrics



  ctx.fillStyle = neutral90 + "BF";
  ctx.beginPath();
  ctx.roundRect(x + Math.max(0, targetWidth - width) / 2, y + Math.max(targetHeight - height, 0) / 2 + vertical_padding, targetWidth - Math.max(0, targetWidth - width), targetHeight - Math.max(targetHeight - height, 0), 10)
  ctx.fill();

  ctx.fillStyle = TEXT_COLOR;
  ctx.textBaseline = "middle";
  ctx.font = `${font}px Karla`;
  ctx.textAlign = "start";

  let dx = x + horizontal_padding + circle_radius + Math.max(0, targetWidth - width) / 2;
  let dy = y + vertical_padding + height / 2 + Math.max(targetHeight - height, 0) / 2;
  drawFilledCircle(dx, dy, ctx, { color: SEGMENTATION_LAYER_COLORS.ABOVE_SKIN }, null, circle_radius)
  dx = dx + circle_radius + horizontal_padding;
  ctx.fillStyle = TEXT_COLOR;
  ctx.fillText(SEGMENTATION_LAYERS.ABOVE_SKIN, dx, dy);
  dx = dx + above_skin.width + horizontal_padding + circle_radius;
  drawFilledCircle(dx, dy, ctx, { color: SEGMENTATION_LAYER_COLORS.EPIDERMIS }, null, circle_radius)
  dx = dx + circle_radius + horizontal_padding;
  ctx.fillStyle = TEXT_COLOR;
  ctx.fillText(SEGMENTATION_LAYERS.EPIDERMIS, dx, dy);
  dx = dx + epidermis.width + horizontal_padding + circle_radius;
  drawFilledCircle(dx, dy, ctx, { color: SEGMENTATION_LAYER_COLORS.LESION }, null, circle_radius)
  dx = dx + circle_radius + horizontal_padding;
  ctx.fillStyle = TEXT_COLOR;
  ctx.fillText(SEGMENTATION_LAYERS.LESION, dx, dy);
  dx = dx + lesion.width + horizontal_padding + circle_radius;
  drawFilledCircle(dx, dy, ctx, { color: SEGMENTATION_LAYER_COLORS.DERMIS }, null, circle_radius);
  dx = dx + circle_radius + horizontal_padding;
  ctx.fillStyle = TEXT_COLOR;
  ctx.fillText(SEGMENTATION_LAYERS.DERMIS, dx, dy);
  dx = dx + dermis.width + horizontal_padding + circle_radius;
  drawFilledCircle(dx, dy, ctx, { color: SEGMENTATION_LAYER_COLORS.SUBCUTIS }, null, circle_radius);
  dx = dx + circle_radius + horizontal_padding;
  ctx.fillStyle = TEXT_COLOR;
  ctx.fillText(SEGMENTATION_LAYERS.SUBCUTIS, dx, dy);
  dx = dx + subcutis.width + horizontal_padding + circle_radius;
  drawFilledCircle(dx, dy, ctx, { color: SEGMENTATION_LAYER_COLORS.OTHER }, null, circle_radius);
  dx = dx + circle_radius + horizontal_padding;
  ctx.fillStyle = TEXT_COLOR;
  ctx.fillText(SEGMENTATION_LAYERS.OTHER, dx, dy);
  return metrics
}

export const drawSegmentationLabelWithActiveLayerToImage = (ctx, x, y, targetWidth, targetHeight, activeLayer, text, config = { ...SEGMENTATIONL_LABEL_CONFIG }) => {
  const TEXT_COLOR = neutralblack


  config.font = config.font - 1
  let metrics = calcActLayerLabelMetrics(activeLayer, text, config)
  while (targetWidth < metrics.width || targetHeight < metrics.height) {
    config.font = config.font - 1
    if (config.font < 1) {
      break;
    }
    metrics = calcActLayerLabelMetrics(activeLayer, text, config)
  }



  const { width, height, font, horizontal_padding, vertical_padding, circle_radius, label } = metrics



  ctx.fillStyle = neutral90 + "BF";
  ctx.beginPath();
  ctx.roundRect(x + Math.max(0, targetWidth - width) / 2, y + Math.max(targetHeight - height, 0) / 2 + vertical_padding, targetWidth - Math.max(0, targetWidth - width), targetHeight - Math.max(targetHeight - height, 0), 10)
  ctx.fill();

  ctx.fillStyle = TEXT_COLOR;
  ctx.textBaseline = "middle";
  ctx.font = `${font}px Karla`;
  ctx.textAlign = "start";

  let dx = x + horizontal_padding + circle_radius + Math.max(0, targetWidth - width) / 2;
  let dy = y + vertical_padding + height / 2 + Math.max(targetHeight - height, 0) / 2;
  drawFilledCircle(dx, dy, ctx, { color: SEGMENTATION_LAYER_COLORS[SEGMENTATION_LAYER_ENUM[activeLayer]] }, null, circle_radius)
  dx = dx + circle_radius + horizontal_padding;
  ctx.fillStyle = TEXT_COLOR;
  ctx.fillText(SEGMENTATION_LAYERS[SEGMENTATION_LAYER_ENUM[activeLayer]], dx, dy);
  dx = dx + label.width + horizontal_padding;
  ctx.fillText(text, dx, dy);
  return metrics
}

const lineToAngle = (ctx, x1, y1, length, angle, color, lineWidth) => {
  if (color) {
    ctx.strokeStyle = color;
  }
  if (lineWidth) {
    ctx.lineWidth = lineWidth;
  }
  angle = (angle - 90) * Math.PI / 180;
  var x2 = x1 + length * Math.cos(angle),
    y2 = y1 + length * Math.sin(angle);

  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();

  return {
    x: x2,
    y: y2
  };
}

const drawDoubleArrow = (ctx, x1, y1, length, angle, width = 3) => {
  var pos = lineToAngle(ctx, x1, y1, length, angle, null, width);
  const lengthArrow = 7
  lineToAngle(ctx, pos.x - width / 2 * Math.cos((angle - 90 - 135) * Math.PI / 180), pos.y - width / 2 * Math.sin((angle - 90 - 135) * Math.PI / 180), lengthArrow, angle - 135, null, width);
  lineToAngle(ctx, pos.x - width / 2 * Math.cos((angle - 90 + 135) * Math.PI / 180), pos.y - width / 2 * Math.sin((angle - 90 + 135) * Math.PI / 180), lengthArrow, angle + 135, null, width);

  lineToAngle(ctx, x1 - width / 2 * Math.cos((angle - 90 - 45) * Math.PI / 180), y1 - width / 2 * Math.sin((angle - 90 - 45) * Math.PI / 180), lengthArrow, angle - 45, null, width);
  lineToAngle(ctx, x1 - width / 2 * Math.cos((angle - 90 + 45) * Math.PI / 180), y1 - width / 2 * Math.sin((angle - 90 + 45) * Math.PI / 180), lengthArrow, angle + 45, null, width);
}


export const drawImageBorder = (ctx, ys = [], color = neutralwhite, lineDash = [10, 10], lineWidth = 2) => {
  ctx.save();
  ctx.beginPath();
  ctx.setLineDash(lineDash);
  ys.forEach(y => { ctx.moveTo(0, y); ctx.lineTo(ctx.canvas.width, y); })
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth
  ctx.stroke();
  ctx.restore();
}

export const drawVerticalLines = (ctx, xs = [], color = neutralwhite, lineDash = [10, 10], lineWidth = 2) => {
  ctx.save();
  ctx.beginPath();
  ctx.setLineDash(lineDash);
  xs.forEach(x => { ctx.moveTo(x, 0); ctx.lineTo(x, ctx.canvas.height); })
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth
  ctx.stroke();
  ctx.restore();
}

export const getMeasurementBoxHeight = (ctx, textPadding, fontSize = 28, text = "9.99") => {
  ctx.font = `${fontSize}px Karla`;
  const textMetrics = ctx.measureText(text);
  const fontHeight = (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent) * 1.6;

  const height = textPadding * 2 + 3 * fontHeight;
  const width = textMetrics.actualBoundingBoxRight + textMetrics.actualBoundingBoxLeft + 2 * textPadding;
  return { width, height, fontHeight }
}

export const drawMeasurements = (ctx, ind, item, startPosition, textPadding, boxPadding, color, drawArrows, fontSize = 28) => {
  ctx.font = `${fontSize}px Karla`;

  const { width, height, fontHeight } = getMeasurementBoxHeight(ctx, textPadding, fontSize)
  const x = startPosition.x - (width + boxPadding) * (ind + 1);
  const y = startPosition.y - height

  ctx.fillStyle = color + "96";
  ctx.beginPath();
  ctx.roundRect(x, y, width, height, 10)
  ctx.fill();

  ctx.fillStyle = "white";
  ctx.textBaseline = "middle";
  ctx.textAlign = "start";
  ctx.fillText(item.dx, x + textPadding, y + fontHeight / 2 + textPadding);
  ctx.fillText(item.dy, x + textPadding, y + textPadding + fontHeight + fontHeight / 2);
  ctx.fillText(item.d, x + textPadding, y + textPadding + 2 * fontHeight + fontHeight / 2);

  if (drawArrows) {
    ctx.strokeStyle = "white";
    const arrowX = x - textPadding
    drawDoubleArrow(ctx, Math.round(arrowX - 2 * textPadding), Math.round(y + textPadding + fontHeight / 2), Math.round(2 * textPadding), 90);
    drawDoubleArrow(ctx, Math.round(arrowX - textPadding), Math.round(y + textPadding + fontHeight + fontHeight / 2 + textPadding), Math.round(2 * textPadding), 0);
    drawDoubleArrow(ctx, Math.round(arrowX - 2 * textPadding / Math.sqrt(2)), Math.round(y + textPadding + 2 * fontHeight + fontHeight / 2 + textPadding / Math.sqrt(2)), Math.round(2 * textPadding), 45);
    return { x: arrowX - 2 * textPadding, y, height }
  }
  return { x, y, height }

}


export const displayVolumeEstimationValue = (ctx, volumeEstimationValue, startPosition, textPadding, boxPadding, fontSize = 28) => {
  ctx.save();
  ctx.font = `${fontSize}px Karla`;

  const { width, height, fontHeight } = getMeasurementBoxHeight(ctx, textPadding, fontSize, "aaaaaaa: 99.9 mm3")
  const x = startPosition.x - (width + boxPadding);
  const y = startPosition.y - height

  ctx.fillStyle = neutral90 + "96";
  ctx.beginPath();
  ctx.roundRect(x, y, width, height, 10)
  ctx.fill();

  ctx.fillStyle = neutralblack;
  ctx.textBaseline = "middle";
  ctx.textAlign = "start";
  ctx.fillText("Volume: " + Math.round(volumeEstimationValue * 10) / 10 + " mm3", x + textPadding, y + textPadding + fontHeight + fontHeight / 2);

  ctx.restore();

  return { x, y, height }

}

export const drawText = (ctx, text, x, y, fontSize) => {
  ctx.beginPath();
  ctx.fillStyle = neutralwhite;
  ctx.font = `${fontSize}px Karla`;
  ctx.fillText(text, x, y);
}


export const drawFreeText = (ctx, text, x, y, width, height, fontHeight, textPadding) => {
  if (width > 0 && height > 0) {
    ctx.fillStyle = neutral90 + "BF";
    ctx.beginPath();
    ctx.roundRect(x, y, width, height, 10)
    ctx.fill();
    ctx.fillStyle = neutralblack;
    const yPadding = (height - Math.trunc(height / fontHeight) * fontHeight) / 2;
    drawMultilineText(ctx, text, x + textPadding, y + yPadding, width - 2 * textPadding, Math.trunc(height / fontHeight), fontHeight)
  }
}

export const drawIntensityValues = (ctx, str, x, y, fontSize, textPadding) => {
  ctx.save();
  ctx.font = `${fontSize}px Karla`;
  ctx.textBaseline = "top"
  const textMetrics = ctx.measureText(str);
  const height = (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent);
  const maxWidth = 2 * x - ctx.canvas.width;
  let width = textMetrics.actualBoundingBoxRight + textMetrics.actualBoundingBoxLeft + 2 * textPadding;
  const maxLine = width < maxWidth ? 1 : Math.ceil((width) / (maxWidth - 2 * textPadding))
  if (maxLine > 1) {
    width = maxWidth
  }
  ctx.fillStyle = neutral90 + "BF";
  ctx.beginPath();
  ctx.roundRect(x - width, y - maxLine * height - (maxLine + 1) * textPadding, width, maxLine * height + (maxLine + 1) * textPadding, 10)
  ctx.fill();
  ctx.fillStyle = neutralblack;

  drawMultilineText(ctx, str, x - width + textPadding, y - maxLine * height - maxLine * textPadding, width - textPadding, maxLine, height + textPadding)

  ctx.restore();
}

const splitWords = (context, word, line, x, y, lineHeight, numOfLine, maxWidth, maxLine) => {

  for (let i = 0; i < word.length; i++) {
    let metrics = context.measureText(line + word.substring(0, i));
    if (metrics.width > maxWidth) {
      line = line + word.substring(0, i - 1)
      context.fillText(line, x, y);
      numOfLine++;
      if (numOfLine === maxLine) {
        return { x, y, numOfLine, line }
      }
      metrics = context.measureText(word.substring(i - 1));
      if (metrics.width > maxWidth) {
        y += lineHeight;
        const res = splitWords(context, word.substring(i - 1), "", x, y, lineHeight, numOfLine, maxWidth, maxLine)
        if (!res) {
          return
        }
        x = res.x
        y = res.y
        numOfLine = res.numOfLine
        line = res.line
        return { x, y, numOfLine, line }
      } else {
        line = word.substring(i) + ' ';
        y += lineHeight;
        return { x, y, numOfLine, line }
      }
    }
  }
}
export const drawMultilineText = (context, text, x, y, maxWidth, maxLine, lineHeight) => {
  const words = text.split(' ');
  let line = '';
  let numOfLine = 0
  context.textBaseline = "top";

  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + ' ';
    let metrics = context.measureText(testLine);
    if (metrics.width > maxWidth) {
      metrics = context.measureText(words[n]);
      if (metrics.width > maxWidth) {
        const res = splitWords(context, words[n], line, x, y, lineHeight, numOfLine, maxWidth, maxLine)
        if (!res) {
          return
        }
        x = res.x
        y = res.y
        numOfLine = res.numOfLine
        line = res.line
      } else {
        context.fillText(line, x, y);
        numOfLine++;
        if (numOfLine === maxLine) {
          return
        }
        line = words[n] + ' ';
        y += lineHeight;
      }
    }
    else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
}




export const drawBigCrossInTheMiddle = (ctx, color = error40, lineWidth = 2, dash = [20, 10]) => {
  const width = ctx.canvas.width
  const height = ctx.canvas.height


  let x1, x2, y1, y2
  if (width < height) {
    x1 = 0
    x2 = width
    y1 = (height - width) / 2
    y2 = height - y1
  } else {
    x1 = (width - height) / 2
    x2 = width - x1
    y1 = 0
    y2 = height
  }


  ctx.save();

  ctx.beginPath();
  ctx.setLineDash(dash);

  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);

  ctx.moveTo(x2, y1);
  ctx.lineTo(x1, y2);

  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.stroke();
  ctx.restore();

}

export const drawBigCircleInTheMiddle = (ctx, radius, lineWidth = 2, color = error40,) => {
  const width = ctx.canvas.width
  const height = ctx.canvas.height


  ctx.save();
  ctx.beginPath();
  ctx.arc(width / 2, height / 2, radius, 0, 2 * Math.PI);
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.stroke();
  ctx.restore();

}
// Draws thresholding box in the center of the optical image, "real" optical image center should be inside this box 
export const drawOpticalCenteringThresholdBoxInTheMiddle = (ctx, threshold = 20, lineWidth = 2, color = error40, lineDash = [10, 20]) => {
  const centerX = Math.round(ctx.canvas.width / 2)
  const centerY = Math.round(ctx.canvas.height / 2)

  const aspectRatio = ctx.canvas.width / ctx.canvas.height;

  const thresholdX = threshold;
  const thresholdY = Math.round(threshold * aspectRatio)

  ctx.save();
  ctx.beginPath();
  ctx.setLineDash(lineDash);

  drawLine(ctx, { x: centerX - thresholdX, y: centerY - thresholdY }, { x: centerX + thresholdX, y: centerY - thresholdY }, color, lineWidth)
  drawLine(ctx, { x: centerX - thresholdX, y: centerY + thresholdY }, { x: centerX + thresholdX, y: centerY + thresholdY }, color, lineWidth)

  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.stroke();
  ctx.restore();

}

// For ultrasound image centering, this lines cover the maing registration line
export const drawSupportingLinesAroundMainLine = (ctx, zoom, threshold = 20, measurementPoints = null, lineWidth = 2, color = error40, lineDash = [10, 20]) => {

  ctx.save();
  ctx.beginPath();
  ctx.setLineDash(lineDash);

  drawLine(ctx,
    relativeToAbolutePointPosition({ x: measurementPoints[0].x - threshold / ctx.canvas.width, y: measurementPoints[0].y },
      zoom, ctx.canvas),
    relativeToAbolutePointPosition({ x: measurementPoints[1].x - threshold / ctx.canvas.width, y: measurementPoints[1].y },
      zoom, ctx.canvas),
    color, lineWidth)

  drawLine(ctx,
    relativeToAbolutePointPosition({ x: measurementPoints[0].x + threshold / ctx.canvas.width, y: measurementPoints[0].y },
      zoom, ctx.canvas),
    relativeToAbolutePointPosition({ x: measurementPoints[1].x + threshold / ctx.canvas.width, y: measurementPoints[1].y },
      zoom, ctx.canvas),
    color, lineWidth)

  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.stroke();
  ctx.restore();

}


export const drawCurve = (ctx, points, color, lineWidth = 0, tension = 1) => {
  ctx.beginPath();

  ctx.moveTo(0, ctx.canvas.height);
  ctx.lineTo(points[0].x, points[0].y);

  for (let i = 0; i < points.length - 1; i++) {
    const p0 = (i > 0) ? points[i - 1] : points[0];
    const p1 = points[i];
    const p2 = points[i + 1];
    const p3 = (i !== points.length - 2) ? points[i + 2] : p2;

    const cp1x = p1.x + (p2.x - p0.x) / 6 * tension;
    const cp1y = p1.y + (p2.y - p0.y) / 6 * tension;

    const cp2x = p2.x - (p3.x - p1.x) / 6 * tension;
    const cp2y = p2.y - (p3.y - p1.y) / 6 * tension;

    ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, p2.x, p2.y);
  }

  ctx.lineTo(ctx.canvas.width, ctx.canvas.height);

  if (lineWidth === 0) {
    ctx.fillStyle = color
    ctx.fill()
  } else {
    ctx.lineWidth = lineWidth
    ctx.strokeStyle = color;
    ctx.stroke();
  }

}

export const drawDashedRect = (ctx, x, y, w, h, lineWidth = 2, color = neutralwhite, lineDash = [10, 10],) => {
  ctx.save();
  ctx.beginPath();
  ctx.setLineDash(lineDash);
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth

  ctx.strokeRect(x - w / 2, y - h / 2, w, h);
  ctx.restore();
}

export default getLesionLocationImage;
