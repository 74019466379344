import * as action from "../actions/patientManagementActions";


/**
 * Set capture action creator
 * @param {Capture data} capture 
 */
export const setCaptureActionCreator = (capture) => {
  return {
    type: action.SET_CAPTURE_INFO,
    payload: { capture },
  };
};

/**
 * Set Patient action creator
 * @param {Patient} patient
 */
export const setPatientActionCreator = (patient) => {
  return {
    type: action.SET_PATIENT_INFO,
    payload: { patient },
  };
};
/**
 * Set RecordList of Lesion action creator
 * @param {Array<Record>} records
 * @param {Number} lesion_id
 */
export const setRecordListOfLesionActionCreator = (records = null, lesionId = null) => {
  return {
    type: action.SET_RECORDS_LIST_OF_LESION,
    payload: { recordList: { lesionId, records } },
  };
};

/**
 * Modify RecordList of Lesion action creator
 * @param {Array<Record>} records
 * @param {Number} lesion_id
 */
export const modifyRecordListOfLesionActionCreator = (record) => {
  return {
    type: action.MODIFY_RECORDS_LIST_OF_LESION,
    payload: { record },
  };
};

/**
 * Record id ti display action creator
 * @param {Number} recordId
 */
export const recordIdToDisplayActionCreator = (recordId) => {
  return {
    type: action.RECORD_ID_TO_DISPLAY,
    payload: { recordIdToDisplay: recordId },
  };
};


/**
 * Set record action creator
 * @param {Record} record
 */
export const setRecordActionCreator = (record) => {
  return {
    type: action.SET_RECORD_INFO,
    payload: { record },
  };
};
/**
 * Set lesion action creator
 * @param {Lesion} lesion
 */
export const setLesionActionCreator = (lesion) => {
  return {
    type: action.SET_LESION_INFO,
    payload: { lesion },
  };
};
/**
 * Set notes action creator
 * @param {Array<Note>} notes
 */
export const setNotesToRecordActionCreator = (notes) => {
  return {
    type: action.SET_NOTES_TO_RECORD,
    payload: { notes },
  };
};
/**
 * Set note action creator
 * @param {Note} note
 */
export const setNoteToLesionActionCreator = (note) => {
  return {
    type: action.SET_NOTE_TO_LESION,
    payload: { note },
  };
};
/**
 * Set note action creator
 * @param {Note} note
 */
export const setDiagnosisToLesionActionCreator = (diagnosis) => {
  return {
    type: action.SET_DIAGNOSIS_TO_LESION,
    payload: { diagnosis },
  };
};
/**
 * Set stale cache action creator
 * @param {boolean} stale stale or not
 */
export const setStaleActionCreator = (stale) => {
  return {
    type: action.SERVE_FROM_STALE,
    payload: { stale },
  };
};
/**
 * Set mobile  view
 * @param {Capture data} capture 
 */
export const viewActionCreator = (view) => {
  return {
    type: action.VIEW,
    payload: { view },
  };
};

/**
 * Set manifest 
 * @param {Object} manifest 
 */
export const manifestActionCreator = (manifest) => {
  return {
    type: action.MANIFEST,
    payload: { manifest },
  };
};


/**
 * Set serial numbe 
 * @param {Stringg} serialnumber  serialnumber
 */
export const serialNumberActionCreator = (serialnumber) => {
  return {
    type: action.DEVICE_SERIAL_NUMBER,
    payload: { serialnumber },
  };
};
/**
 * Set fw to update 
 * @param {Stringg} serialnumber  serialnumber
 */
export const fwToUpdateActionCreator = (fwToUpdate) => {
  return {
    type: action.FW_TO_UPDATE,
    payload: { fwToUpdate },
  };
};
/**
 * Set device info 
 * @param {Stringg} serialnumber  serialnumber
 */
export const deviceInfoActionCreator = (deviceInfo) => {
  return {
    type: action.DEVICE_INFO,
    payload: { deviceInfo },
  };
};


