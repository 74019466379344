class Lesion {
  /**
   * Constructor
   * @param {String} coordX x coordonate of lesion location
   * @param {String} coordY y coordinate of lesion location
   * @param {String} diagnosis diagnosis
   * @param {String} groundTruth ground toruth
   * @param {String} id id
   * @param {String} note note
   * @param {String} favourite favourite
   * @param {String} optical optical image
   * @param {String} opticalDate date od optical image
   * @param {String} sinceExamDate time since last exam
   * @param {String} opticalThumbnailId optical thumbanil
   * @param {String} patientId patient id
   * @param {String} thumbnail thumbnail of image
   * @param {Object} imageUrl image url (Object)
   */
  constructor(
    coordX,
    coordY,
    diagnosis,
    diagnosisTitle,
    groundTruth,
    id,
    local_id,
    note,
    favourite,
    optical,
    opticalUrl,
    rawLastExamDate,
    lastExamDate,
    sinceExamDate,
    opticalThumbnailId,
    opticalThumbnailUrl,
    patientId,
    thumbnail,
    imageUrl,
    freeDiagnosis,
    diagnosisOrigin,
    usId, usUrl,
    usThumbnail, usThumbnailUrl,
    num_of_visits,
    records,
    tool_action_text,
    has_favourite_record,
  ) {
    this.coordX = coordX;
    this.coordY = coordY;
    this.diagnosis = diagnosis;
    this.diagnosisTitle = diagnosisTitle;
    this.groundTruth = groundTruth;
    this.id = id;
    this.note = note;
    this.favourite = favourite;
    this.opticalId = optical;
    this.opticalUrl = opticalUrl;
    this.rawExamDate = rawLastExamDate
    this.examDate = lastExamDate;
    this.sinceExamDate = sinceExamDate;
    this.opticalThumbnailId = opticalThumbnailId;
    this.opticalThumbnailUrl = opticalThumbnailUrl;
    this.patientId = patientId;
    this.thumbnail = thumbnail;
    this.imageUrl = imageUrl;
    this.freeDiagnosis = freeDiagnosis;
    this.diagnosisOrigin = diagnosisOrigin;

    this.local_id = local_id

    this.usId = usId;
    this.usUrl = usUrl;
    this.usThumbnail = usThumbnail;
    this.usThumbnailUrl = usThumbnailUrl;

    this.numOfVisits = num_of_visits
    this.records = records

    this.toolActionTexts = tool_action_text

    this.hasFavouriteRecord = has_favourite_record
  }
}
export default Lesion;
